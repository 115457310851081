<template>
  <div>
    <h3 class="modal__title">About Telling.ai</h3>
    <div class="modal__body">
      <p>
        Telling.ai is transforming respiratory care by remotely monitoring voice
        signals. We are building products that use voice to detect deviations
        from the patient’s respiratory function to assess whether their
        breathing is stable, worsening or improving. These products will be
        accessible via mobile phones to help manage the care of the over 1
        billion people who suffer from chronic or acute respiratory diseases.
      </p>
      <p>
        The founding team is led by experienced entrepreneurs along with
        researchers and graduate students from the Carnegie Mellon School of
        Computer Science and Language Technology Institute.
      </p>
      <div class="modal__footer">
        <button class="button button_secondary button_md" @click="$emit('close')">close</button>
      </div>
    </div>
  </div>
</template>
<script>
import { saveLog } from "@/modules/logger";

export default {
  mounted() {
    saveLog("pop-up-about-us");
  },
};
</script>